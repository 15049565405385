import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import history from '@/store/history';
import MainModal from '@/components/modals/main';
import SocialSticky from '@/components/social-sticky/index';
import { ROUTESLIST } from '@/static/urls';
import ModalRadioBtn from './modal-radio';
import brands from './data';
import './index.scss';

class ClearSamplePage extends Component {
    constructor(props) {
        super(props)        
        this.state = {
          isOpen: false,
          currentCheck: '',
        }
        this.closeModal =  this.closeModal.bind(this);
        this.openModal =  this.openModal.bind(this);
        this.submitModal =  this.submitModal.bind(this);
        this.changeRadio =  this.changeRadio.bind(this);       
    }
    closeModal() {
        this.setState({isOpen: false});
    }
    openModal() {
        this.setState({isOpen: true});
    }
    submitModal() {
        this.setState({isOpen: false});
        if( this.state.currentCheck.length > 0) {
            history.push(this.state.currentCheck);
        }
    }
    changeRadio(val) {
        this.setState({currentCheck: val});
    }

    render() {
        const modal = (this.props.isUserAuth) ? this.renderUserModal() : this.renderNonUserModal();       
        return (
            <main className="page">
                <section className="top-banner" id="top-banner-clear-sample">
                    <div className="content">
                        <div className="top-banner__wrap">
                            <div className="top-banner__content">
                                <h2 className="head-shadow">Clear Samples</h2>
                                <h3 className="head">Clear your samples quickly & accurately</h3>                                
                                <div className="top-banner__info">
                                <p>
                                    Records, TV/Film or Video – create an account and follow four easy steps 
                                    as part of engaging Ample Samples ™ to clear your samples and songs quickly and accurately.
                                </p>                            
                                </div>
                                <div className="top-banner__btns">
                                    <button className="btn btn-primary" type="button" onClick={ this.openModal }>START NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cs-sec">
                    <div className="content">
                        <h3 className="cs-sec__title">OUR CLIENTS INCLUDE</h3>
                        <div className="cs-sec__desc">
                            <p>
                                We take pride in our passion for music and culture, as well as the amazing relationships 
                                we’ve developed with a variety of artists, managers, record labels and licensors. 
                                With almost 20 years of experience in the music clearance space.</p>
                        </div>
                        <div className="cs-sec__brands">
                        {brands.map((item) => {
                                return (
                                    <a className="cs-sec__brand" 
                                        href={ item.url }                                        
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={ item.id }>
                                        <img src={ item.image } alt={ item.alt } />
                                    </a>
                                );
                                }    
                            )}
                        </div>
                    </div>
                </section>
                { modal }
                < SocialSticky />
            </main>
        )
    }

    renderUserModal() {
        const { isOpen, currentCheck } = this.state;
        return(
            <MainModal open={isOpen} onClose={this.closeModal} >
                    <h4 className="main-modal__head">What do you want to Clear?</h4>
                        <div>
                            <ModalRadioBtn 
                                icon="as-icon as-icon_sample"
                                value={ ROUTESLIST.UPLOAD_SAMPLE } 
                                currentValue={currentCheck} 
                                name="choise-item" 
                                title="Samples" 
                                note="I am sampling someone’s song" 
                                onChange={ this.changeRadio }                            
                            />
                            <ModalRadioBtn 
                                icon="as-icon as-icon_folder-sync"
                                value={ ROUTESLIST.UPLOAD_SYNC } 
                                currentValue={currentCheck} 
                                name="choise-item" 
                                title="Sync" 
                                note="I want to use a song(s) for Film/ TV/ Video" 
                                onChange={ this.changeRadio }
                            />
                            
                        </div>
                    <div className="main-modal__act">
                        <button className="btn btn-primary" type="button" onClick={ this.submitModal }>Continue</button>
                        
                    </div>
                </MainModal>
        );

    }
    renderNonUserModal() {
        const { isOpen } = this.state;
        return (
            <MainModal open={isOpen} onClose={this.closeModal} >                    
                        <div className="modal-msg-content">
                            Please Sign Up or Login to your account if you
                            wish to complete the process.
                        </div>
                    <div className="modal-many-btns">
                        <Link to={{ 
                            pathname: ROUTESLIST.LOGIN, 
                            state: { redirectUrl:  ROUTESLIST.CLEAR_SAMPLE}
                            }} className="btn btn-primary">
                                Login   
                        </Link>
                        <Link to={ROUTESLIST.SIGNUP} className="btn btn-primary">
                                Sign up  
                        </Link>
                    </div>
                </MainModal>
        );
    }
}

function mapStateToProps(state) { 
    return {
        isUserAuth: state.auth.isAuth
    }
}

export default connect(mapStateToProps, null)(ClearSamplePage);

/*
<ModalRadioBtn 
    icon="as-icon as-icon_brand-mech"
    value={ ROUTESLIST.UPLOAD_MECH } 
    currentValue={currentCheck} 
    name="choise-item" 
    title="Mechanical" 
    note="I want permission to remake someone’s song" 
    onChange={ this.changeRadio }
/> 

 */